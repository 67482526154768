import Vue from 'vue'
import VueRouter from 'vue-router'
import { useloggedUserInfo, moduleStore } from "../stores/UserInfo";
import Callback from '../views/auth/Callback.vue'
import Unauthorized from '../views/auth/Unauthorized.vue'
import authService from '@/services/authservice';
import DataService from "@/services/DataService";
import common from '@/assets/common/vue-common.js'
Vue.use(VueRouter)
const routes = [
        {
            path: "/",
            name: "home",
            component: () => import("@/components/Dashboard/Dashboard.vue"),
            meta: { title: 'Dashboard' }
        },        
        {
            path: '/callback',
            name: 'callback',
            component: Callback
        },
        {
            path: '/logout',
            name: 'logout',
            component: Unauthorized
        },
        {
            path: '/unauthorized',
            name: 'Unauthorized',
            component: Unauthorized
        },      
        {
            path: "/Dashboard",
            name: "UserDashboard",          
            component: () => import("@/components/Dashboard/Dashboard.vue"),
            meta: { 
                title: 'Dashboard',
                checkPrivilege:false,
            } 
        },     
        {
            path: "/Test",
            name: "Test",          
            component: () => import("@/views/Test.vue"),
            meta: {
                 title: 'Test',
                 checkPrivilege:false,
            } 
        },     
        {
            path: "/Custom/Layouts",
            name: "CustomLayoutListing",          
            component: () => import("@/views/CustomLayoutListing.vue"),
            meta: { 
                title: 'Custom Layout Listing',
              //  checkPrivilege:false,
                breadcrumb: [
                    { name: "Custom Layout", }
                ],
            },
            
        },
        {
            path: "/Custom/ManageLayout/:CustomlayoutId",
            name: "ManageCustomLayout",          
            component: () => import("@/views/ManageCustomLayout.vue"),
            meta: { 
                title: 'Manage Layout Custom',
               checkPrivilege:false,
               breadcrumb: [
                { name: "Custom Layout", link: "/Custom/Layouts" },
                { name: "Manage CustomLayout"},
             ],
             } 
        },
        {
            path: "/Custom/Fields",
            name: "CustomFieldsListing",          
            component: () => import("../views/LayoutCustomFields/CustomFieldsListing.vue"),
            meta: { 
                title: 'Custom Fields Listing',
              // checkPrivilege:false,
               breadcrumb: [
                { name: "Custom Layout", link: "/Custom/Layouts" },
                { name: "Custom Fields"},
               
             ],
            } 
        }              
];
const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})


const DEFAULT_TITLE = 'Home';
router.beforeResolve((to, from, next) => {
    document.getElementById("divProgress1").style.display = 'none';
    document.title = 'TALYGEN - ' + (to.meta.title || DEFAULT_TITLE);
    if (to.path == '/callback' || to.path == '/unauthorized' || to.path == '/logoutcallback') {
        next()
    }
    else {
        if (to.path == '/logout') {
            authService.logOut();
        } 
        else {
            const ustore = useloggedUserInfo();
            let app = ustore.GetUserInfo;
            if (app) {
                let isCheckPrivilege = true;
                if(to.meta.hasOwnProperty('checkPrivilege'))
                {
                    isCheckPrivilege = to.meta.checkPrivilege;
                }
                if(isCheckPrivilege) {
                    let controllerName = "", actionName = "";
                    if (to.meta.hasOwnProperty('actionName') && to.meta.hasOwnProperty('controllerName')) {
                        controllerName = to.meta.controllerName;
                        actionName = to.meta.actionName;
                    }
                    else{
                        let pathArray = to.path.split("/");
                        if(pathArray.length > 2){
                            controllerName = pathArray[1];
                            actionName = pathArray[2];
                        } else{
                            controllerName = pathArray[0];
                            actionName = pathArray[1];
                        }
                    }
                    if(controllerName != "" && actionName != "") {
                        DataService.CheckPrivilege(`controller=${controllerName}&action=${actionName}`).then((value) => {
                            if (value.data)
                                next()
                            else
                                next('/unauthorized');
                            },
                            (error) => {
                                console.log(error);
                            }
                        );
                    } else {
                        next()
                    }
                } else {
                    next()
                }
            }
            else {
                console.log('authenticating a protected url:' + to.path);
                next();
            }
        }
    }
});


export default router;
